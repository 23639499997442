<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.new_m", {
                                m: $t("message.quizPlan"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
                  <el-form
                    ref="form"
                    status-icon
                    :model="form"
                    :rules="rules"
                  > 
                    <el-row :gutter="20">
                        <el-col :span="12">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                              {{ $t("message.nameGiven") }}
                          </span>
                          <el-form-item
                              prop="name"
                              class="label_mini"
                          >
                              <crm-input
                                  :size="'medium'"
                                  :inputValue="form.name"
                                  v-model="form.name"
                                  :placeholder="$t('message.nameGiven')"
                                  :class="mode ? 'input__day' : 'input__night'"
                              ></crm-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("message.daly_question_count") }}
                          </span>
                          <el-form-item
                              prop="daly_question_count"
                              class="label_mini">
                              <crm-input  :class="mode ? 'input__day' : 'input__night'"
                                :inputValue="form.daly_question_count"
                                :placeholder="$t('message.daly_question_count')"
                                v-model="form.daly_question_count"
                                size="medium"
                                :type="'number'"
                              ></crm-input>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                            {{ $t("message.question_category_list") }}
                          </span>
                          <el-form-item
                              prop="title"
                              class="label_mini">
                                <select-question-categories
                                  :size="'medium'"
                                  :class="mode ? 'input__day' : 'input__night'"
                                  :multiple="true"
                                  v-model="form.question_category_ids" >
                                </select-question-categories>
                          </el-form-item>
                        </el-col>
                        <el-col :span="12">
                          <div class="app-form__group mb-4">
                            <span class="input--label d-block mb-2"> {{$t('message.from_date')}} </span>
                            <el-form-item
                            prop="from_date"
                            class="label_mini"
                            >
                            <el-date-picker
                            v-model="form.from_date"
                            :size="'medium'"
                            type="date"
                            class="d-block w-100"
                            :class="mode ? 'input__day' : 'input__night'"
                            placeholder="Pick a month"
                            :clearable="false"
                            :picker-options="{ disabledDate: (time) => disabledEndDate(time) }">
                          </el-date-picker>
                          </el-form-item>
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div class="app-form__group mb-4">
                            <span class="input--label d-block mb-2"> {{$t('message.to_date')}} </span>
                            <el-form-item
                            prop="to_date"
                            class="label_mini"
                        >
                            <el-date-picker
                              v-model="form.to_date"
                              :size="'medium'"
                              type="date"
                              class="d-block w-100"
                              :class="mode ? 'input__day' : 'input__night'"
                              placeholder="Pick a month"
                              :clearable="false"
                              :picker-options="{ disabledDate: (time) => disabledEndDate(time, form.from_date) }">
                            </el-date-picker>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div class="app-form__group mb-4">
                            <span class="input--label d-block mb-2"> {{$t('message.send_question_time')}} </span>
                            <el-form-item
                            prop="send_question_time"
                            class="label_mini"
                        >
                              <el-time-select
                              required
                              :size="'medium'"
                              :placeholder="$t('message.send_question_time')"
                              v-model="form.send_question_time"
                              :picker-options="{
                                start: '07:00',
                                step: '01:00',
                                end: '22:00',
                              }"
                              class="w-100"
                              :class="mode ? 'input__day' : 'input__night'"
                            ></el-time-select>
                            </el-form-item>
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div class="app-form__group mb-4">
                            <div class="app-form__group mb-4">
                              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{ $t("message.company") }}</span
                              >
                              <el-form-item prop="company_id">
                                <select-company
                                  :id="form.company_id"
                                  v-model="form.company_id"
                                  :size="'medium'"
                                ></select-company>
                              </el-form-item>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div class="app-form__group mb-4">
                            <div class="app-form__group mb-4">
                              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                                {{ $t("message.branch") }}</span
                              >
                              <el-form-item prop="branch_id">
                                <select-branch
                                  :company_id="form.company_id"
                                  v-model="form.branch_id"
                                  :id="form.branch_id"
                                  :size="'medium'"
                                ></select-branch>
                              </el-form-item>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="12">
                          <div class="app-form__group mb-4">
                            <div class="app-form__group mb-4">
                              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                                $t("message.department")
                              }}</span>
                              <el-form-item prop="department_id">
                                <select-department
                                  :branch_id="form.branch_id"
                                  v-model="form.department_id"
                                  :id="form.department_id"
                                  :size="'medium'"
                                ></select-department>
                              </el-form-item>
                            </div>
                          </div>
                        </el-col>
                        <el-col :span="3">
                          <div class="app-form__group mb-4">
                            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
                              {{ $t("message.all") }} {{ $t("message.staff") }}
                            </span>
                            <el-switch v-model="form.for_staffs"> </el-switch>
                          </div>
                        </el-col>
                        <el-col :span="9" v-if="(!form.for_staffs)">
                          <div class="app-form__group mb-4">
                            <div class="app-form__group mb-4">
                              <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{
                                $t("message.staff")
                              }}</span>
                              <el-form-item prop="staff_ids">
                                <select-staff-by-department
                                  :department_id="form.department_id"
                                  :multiple="true"
                                  v-model="form.staff_ids"
                                  :id="form.staff_ids"
                                  :size="'medium'"
                                ></select-staff-by-department>
                              </el-form-item>
                            </div>
                          </div>
                        </el-col>
                    </el-row>
                  </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import SelectStaffByDepartment from "@/components/filters/inventory/select-staff-by-department";

/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
import moment from "moment";


export default {
    mixins: [form, drawer],
    name: "QuizPlanController",
    components: {
      SelectStaffByDepartment
    },
    data() {
        return {
         
        };
    },
    created() {
    },
    computed: {
        ...mapGetters({
            rules: "quizPlan/rules",
            model: "quizPlan/model",
            columns: "quizPlan/columns",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            save: "quizPlan/store",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                  this.form.from_date = moment(this.form.from_date).format("YYYY-MM-DD"),
                  this.form.to_date = moment(this.form.to_date).format("YYYY-MM-DD"),
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        disabledEndDate(date, departureDate) {
          // If departureDate then return valid dates after departureDate
          // let n = new Date();
          if (departureDate) {
            return date.getTime() < new Date(departureDate); 
          } else {
            var d = new Date();
            d.setDate(d.getDate() - 1);
            // If !departureDate then return valid dates after today
            return date.getTime() <=d;
          }
        }
      
    },
};
</script>

